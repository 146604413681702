import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActionItemModel } from '../action-button/action-button.component';
import { UniperAuthService } from '../../services/uniperAuth.service';

@Component({
  selector: 'app-ppa-inventory-header',
  templateUrl: './ppa-inventory-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PPAInventoryHeaderComponent {
  addItems: ActionItemModel[] = [
    // {
    //   Heading: 'Market Location (MaLo)',
    //   Description: 'Manage Market Location (MaLo)',
    //   // Route: '/addobject/synthetic-curve',
    // },
    // {
    //   Heading: 'MaLo Deal Map',
    //   Description: 'Manage Deal Map',
    //   // Route: '/addobject/historic-dataset',
    // },
    // {
    //   Heading: 'Generation Unit',
    //   Description: 'Manage Generation Unit',
    //   Route: '/addppaobject/generation-unit',
    // },
    {
      Heading: 'Asset',
      Description: 'Manage Asset',
      Route: '/addppaobject/asset',
    },
    {
      Heading: 'MaLo',
      Description: 'Manage MaLo',
      Route: '/addppaobject/maLo',
    },
    {
      Heading: 'Transaction',
      Description: 'Manage Transaction',
      Route: '/addppaobject/transaction',
    },
    {
      Heading: 'Business Partner',
      Description: 'Manage Business Partner',
      Route: '/addppaobject/business-partner',
    }
  ];

  constructor(private authService: UniperAuthService){

  }

  hasInventoryAccess(){
    return this.authService.hasInventoryAccess;
  }
}
