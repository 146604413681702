import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class NavService {
  constructor(private router: Router) {}

  goTo(route: string, state: any = null, queryParams = {}) {
    return this.router.navigate([route], { state, queryParams });
  }

  goToValuationCockpit(
    dealTermId: string,
    valuationTaskId: string | null = null,
    valuationRunId: string | null = null
  ) {
    this.router.navigateByUrl(
      `/valuationcockpit?dealTermId=${dealTermId}&valuationTaskId=${valuationTaskId}&valuationRunId=${valuationRunId}`
    );
  }

  goToValuationResult(
    dealTermId: string,
    valuationTaskId: string | null = null,
    valuationRunId: string | null = null
  ) {
    this.router.navigateByUrl(
      `/valuation-results?dealTermId=${dealTermId}&valuationTaskId=${valuationTaskId}&valuationRunId=${valuationRunId}`
    );
  }

  goToAddTimeSeriesRevision(parentRevisionId: number | null = null) {
    this.goTo(`addobject/timeseries-revision`, null, {
      parentRevisionId: parentRevisionId,
    });
  }

  goToDealTerms() {
    this.goTo('dealterms');
  }

  goToDataManagement() {
    this.goTo('data-management');
  }

  goToPPAInventory() {
    this.goTo('ppa-inventory');
  }

  goToAddValuationTask() {
    this.router.navigateByUrl('/addobject/valuationtask');
  }

  goToAddSyntheticCurve() {
    this.router.navigateByUrl('/addobject/synthetic-curve');
  }

  goToManageDealTerm() {
    this.router.navigateByUrl('/manage/dealterm');
  }

  goToManageAssetObject(ID: any) {
    this.router.navigateByUrl(`/ppamanageobject/asset?ID=${ID}`);
  }

  goToManageMaLoObject(ID: any) {
    this.router.navigateByUrl(`/ppamanageobject/maLo?ID=${ID}`);
  }

  goToManageTransactionObject(ID: any) {
    this.router.navigateByUrl(`/ppamanageobject/transaction?ID=${ID}`);
  }

  goToManageBusinessPartnerObject(ID: any) {
    this.router.navigateByUrl(`/ppamanageobject/business-partner?ID=${ID}`);
  }

  goToAddGenerationUnitObject(PPA_ASSET_ID: any) {
    this.router.navigateByUrl(`/addppaobject/generation-unit?PPA_ASSET_ID=${PPA_ASSET_ID}`);
  }

  goToAddDealObject(ID: any) {
    this.router.navigateByUrl(`/addppaobject/ppa-deal?PPA_TRANSACTION_ID=${ID}`);
  }

  goToAddContractObject(ID: any) {
    this.router.navigateByUrl(`/addppaobject/ppa-contract?PPA_TRANSACTION_ID=${ID}`);
  }

  goToManageSettlementRelation(data: any) {
    this.router.navigateByUrl(`/ppamanageobject/settlement-relation?PPA_DEAL_ID=${data.ID}`);
  }

  goToManageGenerationBindings(data: any) {
    this.router.navigateByUrl(`/ppamanageobject/generation-bindings?PPA_DEAL_ID=${data.ID}`);
  }

  goToManagePPADealObject(PPA_TRANSACTION_ID: any, ID: any) {
    this.router.navigateByUrl(`/ppamanageobject/ppa-deal?PPA_TRANSACTION_ID=${PPA_TRANSACTION_ID}&ID=${ID}`);
  }

  goToManagePPAContractObject(PPA_TRANSACTION_ID: any, ID: any) {
    this.router.navigateByUrl(`/ppamanageobject/ppa-contract?PPA_TRANSACTION_ID=${PPA_TRANSACTION_ID}&ID=${ID}`);
  }

  goToManageGenerationUnitObject(PPA_ASSET_ID: any, ID: any) {
    this.router.navigateByUrl(`/ppamanageobject/generation-unit?PPA_ASSET_ID=${PPA_ASSET_ID}&ID=${ID}`);
  }

  goToManageHistoricDataset() {
    this.router.navigateByUrl('/manage/historic-dataset');
  }

  goToCloneDealTerm() {
    this.router.navigateByUrl('/addobject/dealterm?cloned=true');
  }

  goToManageForwardCurveReference() {
    this.router.navigateByUrl('/manage/forwardcurve-reference');
  }

  goToAddForwardCurveRevisionBasedOnReference() {
    this.router.navigateByUrl(
      '/addobject/timeseries-revision-based-on-reference'
    );
  }

  goToManageTimeSeries() {
    this.router.navigateByUrl('/manage/timeseries');
  }

  goToManageTimeSeriesRevision() {
    this.router.navigateByUrl('/manage/timeseries-revision');
  }

  goToManageModelResult() {
    this.router.navigateByUrl('/manage/model-result');
  }

  goToManageValuationTask(valuationTaskId: string) {
    this.router.navigateByUrl(
      `/manage/valuationtask?valuationTaskId=${valuationTaskId}`
    );
  }

  goToExecuteValuationTask() {
    this.router.navigateByUrl('/valuationtask/execute');
  }

  goToManageVolatalityReference() {
    this.router.navigateByUrl('/manage/volatility-reference');
  }
}
