import { FormControl } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { first, map, of } from 'rxjs';
import { ApiService } from '../shared/services/api.service';
import { ContractProfile } from './contractProfile.model';
import { ClonedDealTerm, DealTerm } from './dealTerm.model';
import {
  EscalatedForwardCurveAsOfDate,
  EscalatedForwardCurveReferenceDataObject,
} from './escalatedForwardCurveReference.model';
import { ForwardCurve } from './forwardcurve.model';
import { ForwardCurveReference } from './forwardcurvereference.model';
import { GenerationProfile } from './generationProfile.model';
import {
  HistoricDataset,
  HistoricDatasetGenerationProfile,
} from './historicDataset.model';
import { ModelResultDataObject } from './modelResultDataObject.model';
import { ObjectMeta } from './objectMeta.model';
import { SpotPrices } from './spotPrices.model';
import { TimeSeriesRevision } from './timeSeriesRevision.model';
import { ValuationRun } from './valuationRun.model';
import { ValuationRunResultsDataObject } from './valuationRunResults.model';
import { ValuationTask } from './valuationTask.model';
import { VolatilityReference } from './volatilityreference.model';

export interface DataObject {
  id: string;
  partitionKey: string;
  objectType: string;
  rmvdMeta: RmvdMeta;
  objectMeta: ObjectMeta;
}

export interface RmvdMeta {
  owner: string;
  group: string;
  createdOnUtc: string;
  lastModifiedOnUtc: string;
}

export type DataObjectTypes =
  | ForwardCurve
  | DataObject
  | ForwardCurveReference
  | ClonedDealTerm
  | DealTerm
  | VolatilityReference
  | ValuationTask
  | ValuationRun
  | ModelResultDataObject
  | ValuationRunResultsDataObject
  | TimeSeriesRevision
  | EscalatedForwardCurveReferenceDataObject
  | EscalatedForwardCurveAsOfDate
  | GenerationProfile
  | ContractProfile
  | SpotPrices
  | HistoricDataset
  | HistoricDatasetGenerationProfile;

export enum DataObjectTypeName {
  ForwardCurve = 'ForwardCurve_TS',
  GenerationProfile = 'GenerationProfile_TS',
  ContractProfile = 'ContractProfile_TS',
  SpotPrices = 'SpotPrices_TS',
  DealTerm = 'DealTerm',
  Asset = 'AssetMaster',
  MaLo = 'MasterMalo',
  TranchMalo = 'TranchMaLo',
  TrancheMalo = 'TrancheMaLo',
  Transaction = 'TransactionObject',
  PPADeal = 'DealObject',
  BusinessPartner = 'BusinessPartner',
  PPAContract = 'ContractObject',
  PPADealDetail = 'DealMaster',
  GenerationUnit = 'GenerationUnit',
  SettlementRelation = 'SettlementRelation',
  GenerationBindings = 'GenerationBindings',
  ForwardCurveReference = 'ForwardCurve_Ref',
  VolatilityReference = 'Volatility_Ref',
  ModelCalibration = 'ModelCalibration_Ref',
  ValuationTask = 'ValuationTask',
  ValuationRun = 'ValuationRun',
  ModelResult = 'ModelResult',
  AggregatedScenarioResult = 'AggregatedScenarioData',
  ValuationRunResults = 'ValuationRunResults',
  TimeSeriesRevision = 'TimeSeriesRevision',
  EscalatedForwardCurveReference = 'EscalatedForwardCurve_Ref',
  EscalatedForwardCurveAsOfDate = 'EscalatedForwardCurve_AsOfDate',
  CalculatedCurve = 'Calculated_Curve',
  HistoricDataset = 'HistoricDataset',
  HistoricDatasetGenerationProfile = 'HistoricDatasetGenerationProfile',
}
